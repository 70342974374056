.unexpectedlyAbsent {
  background-image: url("https://cards.scryfall.io/art_crop/front/a/1/a1501a42-e233-423b-b68a-1b76630bbccc.jpg");
}

.zhalfirinVoid {
  background-image: url("https://cards.scryfall.io/art_crop/front/d/4/d481d871-d1e3-439b-bfd5-5b2212f9b0c8.jpg");
}

.totallyLost {
  background-image: url("https://cards.scryfall.io/art_crop/front/8/c/8ccc3190-3220-4f23-b7aa-421e7ed104e9.jpg");
}

.curiosity {
  background-image: url("https://cards.scryfall.io/art_crop/front/4/b/4b47cada-7f7d-4da9-a1a9-886fcde8f5d4.jpg");
}

.oneWithNothing {
  background-image: url("https://cards.scryfall.io/art_crop/front/5/a/5a5841fa-4f30-495a-b840-3ef5a2af8fad.jpg");
}

.lostInTheWoods {
  background-image: url("https://cards.scryfall.io/art_crop/front/5/8/5865603c-0a5e-45c3-84e3-2dc3b4cf0cf7.jpg");
}

.barrenGlory {
  background-image: url("https://cards.scryfall.io/art_crop/front/1/2/12220d53-3356-4541-aa43-a0de6ed3f7d0.jpg");
}

.possibilityStorm {
  background-image: url("https://cards.scryfall.io/art_crop/front/8/5/858aa831-b491-4f1e-bb56-33eeca14771d.jpg");
}
